export const filter = {
    inputs: [],
    selects: [
        {
            name: 'type_id',
            col: '3',
            options: [
                {
                    value: '',
                    name: 'Todos os Tipos'
                },
                {
                    value: '1',
                    name: 'Tecnologia'
                },
                {
                    value: '2',
                    name: 'Vendas'
                },
                {
                    value: '3',
                    name: 'Marketing'
                },
            ]
        },
    ]
};
