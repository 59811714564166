<template>
    <Layout>
        <FilterBase
            session="Notifications"
            :filter="filter"
        />

        <div class="card mb-0 mt-3">
            <TableList
                session="Notifications"
                url="notificacoes/"
                :table="table"
                :keys="keys"
            >

                <template #title="{value}">

                    <router-link :to="'/notificacoes/cadastrar/' + value.id">

                        <div class="media flex-nowrap align-items-center"
                             style="white-space: nowrap;">
                            <div class="media-body">

                                <div class="d-flex flex-column">
                                    <p class="mb-0">
                                        <strong class="js-lists-values-name text-link">{{
                                                value.title
                                            }}</strong>
                                    </p>
                                </div>

                            </div>
                        </div>
                    </router-link>

                </template>

                <template #total_answers>
                    12
                </template>

                <template #mentorings="{value}">
                    <router-link :to="'/mentorias/detalhes/' + mentoring.id" v-for="(mentoring, index) in value.mentorings"
                                 :key="'link-mentoring-' + index">
                        <b-badge variant="info" class="m-2 p-2 fs-12 pointer">
                            {{ mentoring.title }}
                        </b-badge>
                    </router-link>
                </template>

            </TableList>
        </div>

    </Layout>
</template>

<script>

import Layout from '@/components/layout/main.vue'
import FilterBase from "@/components/base/filter-component.vue";
import TableList from "@/components/base/table-list.vue";
import {filter} from "@/views/dashboard/js/filter";
import {keys, table} from "@/views/dashboard/js/table";
import {setSessions} from "@/components/composables/setSessions";

export default {
    data() {
        return {
            filter,
            table,
            keys
        };
    },
    components: {
        TableList,
        FilterBase,
        Layout
    },

    methods: {},
    mounted() {
        if (!localStorage.getItem('Notifications')) setSessions('Notifications');
    },
};
</script>

<style scoped>
.item-chat:hover {
    background: #7e7e7e !important;
}

.page-section .form-control:focus {
    box-shadow: none !important;
    border-bottom: solid 1px #DBDBE0 !important;
    border-radius: 0 !important;
}

.max {
    width: 60%;
}

span.bg-light.message {
    border-radius: 8px;
    max-width: 75%;
    float: right;
}

span.bg-success.message {
    border-radius: 8px;
    max-width: 75%;
    float: left;
//background: #a3ff75!important; background: #c4fca9 !important;
}

.hour {
    font-size: 10px;
    font-style: italic;
    margin: 0 10px;
}

</style>
