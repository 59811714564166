export const table = [
    {
        column: 'Títul0',
    },
    {
        column: 'Respostas',
    },
];

export const keys = ['title', 'total_answers'];
